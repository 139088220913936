<template>
  <template v-for="node in nodes" :key="node.key">
    <component
      :is="node.content.blok.content.component"
      v-if="node.type === 'blok' && node.content.blok.content"
      :blok="node.content.blok.content"
    />
    <div v-else v-html="node.content" />
  </template>
</template>

<script setup>
const props = defineProps({ blok: Object | String });
const nodes = [];
Object.entries(props.blok.content || {}).forEach(([key, node]) => {
  if (node.type === "blok") {
    const blok = {
      content: node.attrs?.body?.[0],
    };
    nodes.push({
      key,
      type: "blok",
      content: {
        blok,
      },
    });
  } else {
    nodes.push({
      key,
      type: "html",
      content: renderRichText({
        type: "doc",
        content: [node],
      }),
    });
  }
});
</script>
